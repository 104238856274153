import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/emilylouie/Documents/workspace2021/emilyslouie.github.io/src/components/projectLayout.js";
import { Link, graphql } from "gatsby";
import { DetailsProject, Blockquote } from "../components/details.js";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import MoreProjects from "../components/moreProjects.js";
export const pageQuery = graphql`
  query {
    allMdx(
      filter: { frontmatter: { company: { ne: null } } }
      sort: { order: ASC, fields: [frontmatter___order] }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            shortTitle
            path
            type
            order
            tags
            company
            thumbnail {
              childImageSharp {
                gatsbyImageData(
                  height: 280
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <div className="header">
  <h1 className="purple">{props.pageContext.frontmatter.shortTitle}</h1>
  <h2>{props.pageContext.frontmatter.title}</h2>
    </div>
    <div className="nda">
  <h3>
    Please note that this project was created as a proof of concept to explore
    the XR space and in the future, Unity has no obligation to implement any of
    the features described.
  </h3>
    </div>
    <p>{`While interning at Unity, I had the opportunity to participate in Hackweek, the company's internal week-long hackathon, and collaborate with a team of developers to prototype two-handed interactions in `}<a href="https://en.wikipedia.org/wiki/Extended_reality" className="link" target="_blank" rel="noreferrer">{`XR`}</a>{` (extended reality, otherwise known as virtual or augmented reality). We created a variety of C# scripts for two-handed interactions and some demos which utilize these features in Unity. These could be bundled with `}<a href="https://docs.unity3d.com/Packages/com.unity.xr.interaction.toolkit@1.0/manual/index.html" className="link" target="_blank" rel="noreferrer">{`Unity's XR Interaction Toolkit`}</a>{` so developers can use it to easily create natural-feeling two-handed interactions.`}</p>
    <DetailsProject role={props.pageContext.frontmatter.role} timeline={props.pageContext.frontmatter.timeline} skills={props.pageContext.frontmatter.tags} mdxType="DetailsProject" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "900px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/a32c69829db4b2bf7c40f2022c1a7d24/9b379/header.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "64%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsTAAALEwEAmpwYAAADGUlEQVQ4yz2T2W8bVRjF57+rEG+ob32AF6AsqkJ2SmIcNxQBxZStG42aQqhQMK2bpkmbUKe2m5okqLUd7/ad8Xib8TLencRpnR/yWPjh6LuL7tF3zzmfRKuCiXa/luGoOUDbgMM6dAxolDmpFaFeMuv/oFGiVyvyytCgWTbvpYJxRL5yRLZ0SL76mkAwhj8QRdFahOJZ0lqLo2Yd6n2SkvmoT9RHrZChpArKmTRKNEqvqiNFqq8JVXq8KBwTqsEl+1XuPPCxGaxz0+HDE+3Qqg8I+2SH5QI1LYuh5VBVFT2TRo0EeOHZJbYbQeoBh8AB0ACu/fgdxe4JkUKdVW+ATBsOmg2zIyOfIZ5IkEwJlLSKpmlkczncbg9ulxvv3y+RHq095qeZiziuLOJ7/ozr31+i2e0SEWk2vHt0gG67CdUiIpEgHo8jhECRZbLZHLKQcbm2WHu4zoOVVSSH4x7OZSd3/3Ay99kkH7xzBqtlBqvVhu2Lb7l29QqNsm4aEw2FCQT3ESKFoiiEw2H8fj9+f4B52wVmz88jpdUcspojUyyzvLLE22+dYuTDdxkbGWF8Yoap0XNU8lloV9FFkuh+iLWHj/jt1yWc9+6zeGuRqclPOX36DF5PCCkcSxGOC8KhJLb5c5y3fMTo2bNMjI8yOf05s9Nj1PQCNCsDYzp19p485eX2M9R4gvvLfxH0+WjpJY5rjQFhQihsbvmYm/+ZX1wLWC68x8TIGOOTM1y0WWgalWH2+tHguImW0ekaRUQwxI5nBw4MTqoaUiwpSAoZx+oWP9xYYXX3LnM332f040+YmrZi//ortjZd6Kk4x0ZxGJ90UmXP60bZ9/NkY4cDPWfqLHm82zz3/YPdfpkbt37H5/Fx+Y4F6zezzH1p5/byEhvrj3m6vkG3opvxaWhFYiGFhesLvHHqTZx/OqFpmD+QhJBJCUEsFkPI/bVMsr9PxEikUiRkmYZRpqPneWUM3G7rBbRUChEI8a9nm0ZWmBqbhNVcmmpepZbPYGSVIWo5lXp+gLIqaBQy9KrF4Uz359/Us16GljE8/w9aenwUutOa8AAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Two Handed XRI header image",
            "title": "Two Handed XRI header image",
            "src": "/static/a32c69829db4b2bf7c40f2022c1a7d24/1cfc2/header.png",
            "srcSet": ["/static/a32c69829db4b2bf7c40f2022c1a7d24/3684f/header.png 225w", "/static/a32c69829db4b2bf7c40f2022c1a7d24/fc2a6/header.png 450w", "/static/a32c69829db4b2bf7c40f2022c1a7d24/1cfc2/header.png 900w", "/static/a32c69829db4b2bf7c40f2022c1a7d24/9b379/header.png 951w"],
            "sizes": "(max-width: 900px) 100vw, 900px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <div id="quickstart"></div>
    <h4>{`Quickstart`}</h4>
    <h2>{`Running the project`}</h2>
    <ol>
      <li parentName="ol">{`Clone the `}<a href="https://github.com/emilyslouie/xri-two-hands" className="link" target="_blank" rel="noreferrer">{`repository`}</a></li>
      <li parentName="ol">{`Open the project in Unity version 2020.3 or later`}</li>
      <li parentName="ol">{`Connect your headset to your computer`}</li>
      <li parentName="ol">{`If you have a PC, press play to enter the scene; If you have a Mac, build the project and deploy it to your headset`}</li>
    </ol>
    <p>{`Alternatively, you can download the .apk `}<a href="https://drive.google.com/file/d/1JBww-QOU9O5tGu4pZjiGOaWTlVwzdslC/view?usp=sharing" className="link" target="_blank" rel="noreferrer">{`here`}</a>{` and side load it.`}</p>
    <br />
    <AnchorLink to="/two-handed-xri#solution" title="Solution" className="link" mdxType="AnchorLink">
  Jump to the final result.
    </AnchorLink>
    <h4>{`Context`}</h4>
    <h2>{`Problem`}</h2>
    <p>{`Currently, the Unity XR Interaction Toolkit only natively supports one-handed interactions, and it's difficult to create custom two-handed interactions as a new developer that feels smooth.`}</p>
    <p>{`When searching Youtube, you can find a ton of tutorials on how to create two-handed interactions in Unity using the XR Interaction Toolkit, however, there is currently no standardized way of implementing it, sometimes the interaction feels jittery and unnatural, and the package is constantly being updated so tutorials can become outdated quickly.`}</p>
    <h2>{`Goal`}</h2>
    <Blockquote text="How might we make implementing natural-feeling, two-handed interactions in XR easier for developers?" mdxType="Blockquote" />
    <p>{`Our goal was to create a collection of scripts that could be used by developers to easily implement a variety of two-handed interactions in XR. These scripts could be included in the Unity XR Interaction Toolkit and swapped in and out as needed. Another goal was to use the scripts created in a series of demo stations to show how they might be used.`}</p>
    <div id="solution"></div>
    <h4>{`Solution`}</h4>
    <h2>{`Final result`}</h2>
    <p>{`We created 9+ scripts that developers can use in their projects to easily implement two-handed interactions. Some of these scripts include:`}</p>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`DynamicAttach`}</code>{` which allows attach points anywhere along the interactable's colliders`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`SymmetricalAttach`}</code>{` which works well for objects like gardening scissors`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`XRMultiGrabInteractable`}</code>{` which allows an interactable to be grabbed more than once`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`XRMultiGrabStabilizedInteractable`}</code>{` which inherits from `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`XRMultiGrabInteractable`}</code>{` and allows the interaction to be more fine-tuned and stable for precise interactions`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`XRTwoHandGrabAndTwist`}</code>{` which allows for an object to be grabbed and twisted easily`}</li>
    </ul>
    <p>{`You can check out the Github repository for the project `}<a href="https://github.com/emilyslouie/xri-two-hands" className="link" target="_blank" rel="noreferrer">{`here`}</a>{`.`}</p>
    <p>{`The video below is a compilation of all the demos we made using the scripts:`}</p>
    <iframe width="100%" height="500" src="https://www.youtube.com/embed/UBxZ3KXKszg" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    <p>{`Personally, I worked on creating a bow and arrow interaction, as well as a hockey stick interaction. I also worked on creating the animated hands to simulate grabbing and trigger interactions.`}</p>
    <h2>{`Reflecting back`}</h2>
    <p>{`Prior to this project, I had only tinkered with XR technologies on a few occasions, including working on a small Hololens project at Microsoft and building a few simple apps with Unity and my Oculus Quest 2. I hadn't really touched the standardized scripts, but with this project I was able to learn a lot more about script design and working in a large team on a 3D project.`}</p>
    <p>{`I had a lot of fun working with the team and everyone was really supportive and willing to help! I definitely learned a lot and hope to use some of these scripts myself in my projects.`}</p>
    <br />
    <h4>{`More case studies`}</h4>
    <MoreProjects projects={props.data.allMdx.edges} current={props.pageContext.frontmatter.order} mdxType="MoreProjects" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      